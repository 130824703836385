import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import classNames from 'classnames/bind';
import Heading from 'components/shared/heading';
import IconicBlock from 'components/shared/iconic-block';

import shape1 from './svg/shape-1.svg';
import shape2 from './svg/shape-2.svg';
import shape3 from './svg/shape-3.svg';

import styles from './improved-security.module.scss';

const cx = classNames.bind(styles);

const ImprovedSecurity = () => {
  const {
    wpSharedBlock: {
      acf: { title, description, features: items },
    },
  } = useStaticQuery(
    graphql`
      query {
        wpSharedBlock(slug: { eq: "improved-security" }) {
          acf {
            title
            description
            features {
              title
              description
              iconName
            }
          }
        }
      }
    `
  );

  return (
    <section className={cx('wrapper')}>
      <div className="container">
        <Heading className={cx('title')} tag="h2" color="tertiary" size="xl">
          {title}
        </Heading>
        <p className={cx('description')} role="doc-subtitle">
          {description}
        </p>

        <ul className={cx('columns is-multiline', 'iconic-blocks-wrapper')}>
          {items.map((item, index) => (
            <li
              className={cx('column is-12-mobile is-6-tablet is-4-desktop', 'iconic-block')}
              key={index}
            >
              <IconicBlock
                color="tertiary"
                descriptionClassName={cx('iconic-block-description')}
                {...item}
              />
            </li>
          ))}
        </ul>

        <img className={cx('shape-1')} src={shape1} alt="" loading="lazy" aria-hidden />
        <img className={cx('shape-2')} src={shape2} alt="" loading="lazy" aria-hidden />
        <img className={cx('shape-3')} src={shape3} alt="" loading="lazy" aria-hidden />
      </div>
    </section>
  );
};

export default ImprovedSecurity;
